<template>
    <section class="pa-4 formular">

        <!--SECTION-->
        <v-row no-gutters :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-4">
            <v-col cols="7" class="pa-3">
                <v-select outlined dense color="gold" ref="cat_sel"
                          :label="g_i18_keys('category')"
                          item-text="alias"
                          item-value="value"
                          :items="select_category"
                          v-model="to_backend.category"
                          hide-details
                          item-color="gold"
                />
            </v-col>
            <v-col cols="5" class="pa-3">
                <v-text-field outlined dense color="gold"
                              :label="g_i18_keys('sort')"
                              v-model="to_backend.sort"
                              hide-details
                />
            </v-col>
        </v-row>

        <!--DOKUMENT-->
        <v-row no-gutters :class="{ 'rgg-grey' : g_dark(this) }" class="grey lighten-4 rounded mb-1">
            <v-col cols="12" class="pa-3">
                <v-textarea v-model="to_backend.title"
                            :label="g_i18_keys('title')"
                            auto-grow hide-details
                            outlined dense color="gold"
                            rows="1"
                />
            </v-col>
        </v-row>
        <v-row no-gutters :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-4 pt-2" align="center">
            <v-col cols="12" align="left" class="pl-3">
                <v-btn
                    class="gold mb-3"
                    dark small depressed dense
                    @click="$refs.inputDocument.click()"
                >
                    <v-icon small left>mdi-file-import-outline</v-icon>
                    <span v-if="!edit">{{$t('content_management.dialog.type_file')}}</span>
                    <span v-else-if="card_item.file">{{$t('content_management.dialog.type_file_change')}}</span>
                    <span v-else>{{$t('content_management.dialog.type_file')}}</span>
                    <v-icon v-if="file_data" dark right>mdi-check-bold</v-icon>
                </v-btn>
                <input v-show="false" type="file" ref="inputDocument" @change="prepareFile">

            </v-col>

            <v-col cols="12" class="px-3 mb-3" v-if="file_data && change">
                <v-alert dense type="success" icon="mdi-file-upload" border="left" class="font-weight-bold">
                    <v-row no-gutters>
                        <v-col cols="12" sm="8">
                            {{ file_data.get('file').name }}
                        </v-col>
                        <v-col cols="12" sm="4" align="right">
                            {{ g_formatBytes(file_data.get('file').size) }}
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>

            <v-col cols="12" class="px-3 mb-3" v-else-if="edit && card_item.file">
                <v-alert dense text type="info" icon="mdi-file" border="left">
                    <v-row no-gutters>
                        <v-col cols="12" sm="8">
                            {{ card_item.file }}
                        </v-col>
                        <v-col cols="12" sm="4" align="right">
                            <!--{{ JSON.parse(card_item.raw_data.text).file_size }}-->
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>

            <v-col cols="12" class="px-3 mb-3" v-else-if="edit && !card_item.file">
                <v-alert dense text type="error" icon="mdi-file-alert" border="left">
                    <span>{{ $t('content_management.dialog.edit_file_missing') }}</span>
                </v-alert>
            </v-col>
        </v-row>

        <!--BUTTONS-->
        <v-row no-gutters v-if="!edit">
            <v-col cols="12" align="center">
                <v-btn depressed class="px-8 gold white--text" @click="create_content" :disabled="!file_data">
                    {{ $t('common.create') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters v-else>
            <v-col cols="4"></v-col>
            <v-col cols="4" align="center">
                <v-btn depressed color="gold" dark class="px-4" @click="update_content">
                    {{ $t('common.save') }}
                </v-btn>
            </v-col>
            <v-col cols="4" align="right">
                <rgg-confirm :confirm="delete_content" type="delete" message="content_management.dialog.delete_confirm_message" />
            </v-col>
        </v-row>

    </section>
</template>

<script>
    import {createContent, deleteContent, updateContent, uploadFile} from "@/api/content";

    export default {
        name: "ContentFileDialog",
        props: {
            edit: Boolean,
            id: Number,
            card_item: Object
        },
        data: () => ({
            to_backend: {
                title: '',
                text: '',
                category: 'Documents',
                sort: '0',
                type: 'File'
            },
            select_category: [],
            file_data: null,
            change: false
        }),
        created() {
            this.select_category = [
                {alias: this.g_i18_filter('documents'), value: 'Documents'},
                {alias: this.g_i18_filter('certificates'), value: 'Certificates'},
            ]

            if(this.edit) {
                let title = this.$t('content_management.dialog.edit_file') + ' ID:' + this.id;
                this.$parent.$emit('action', {func: 'setToolbarTitle', data: title});

                for (const prop of Object.keys(this.to_backend)) {
                    this.to_backend[prop] = this.card_item.raw_data[prop];
                }
            }
        },
        methods: {
            create_content() {
                this.$root.$emit('overlay', true);
                /*let obj = {
                    'file_size': this.g_formatBytes(this.file_data.get('file').size) ,
                    'file_type': 'jpg'
                }
                this.to_backend.text = JSON.stringify(obj);*/
                createContent(this.to_backend).then( (response) => {
                        if(response) {
                            this.upload_file(response.data);
                            //this.$parent.$emit('action_dialog', {func: 'close', data: 'content_created'});
                        }
                    }).catch( () => {
                        this.$root.$emit('overlay', false);
                        this.file_data = null;
                        this.$root.$emit('notification', {
                            type: 'error',
                            icon: 'server_error',
                            msg: 'notification.server_error'
                        });
                    })
            },

            update_content() {
                this.$root.$emit('overlay', true);
                /*let obj = {
                    'file_size': this.g_formatBytes(this.file_data.get('file').size) ,
                    'file_type': 'jpg'
                }
                this.to_backend.text = JSON.stringify(obj);*/
                updateContent(this.id, this.to_backend).then( (response) => {
                        if(response && this.change) {
                            this.upload_file(this.id);
                            //this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                        } else {
                            this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                        }
                    })
            },

            delete_content() {
                this.$root.$emit('overlay', true);
                deleteContent(this.id).then( () => {
                        this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_deleted'});
                    }).catch( () => {
                        this.$root.$emit('overlay', false);
                        this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                        this.$root.$emit('notification', {
                            type: 'error',
                            icon: 'server_error',
                            msg: 'notification.server_error'
                        });
                    })
            },

            prepareFile() {
                this.change = true;
                let formData = new FormData();
                formData.append('file', this.$refs.inputDocument.files[0]);

                this.file_data = formData;
            },

            upload_file(_id) {
                uploadFile(_id, this.file_data).then( () => {
                    if(this.edit) {
                        this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                    } else {
                        this.$parent.$emit('action_dialog', {func: 'close', data: 'content_updated'});
                    }

                }).catch( () => {
                    this.$root.$emit('overlay', false);
                    this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                    this.$parent.$emit('action_dialog', {func: 'close', data: 'content_updated'});
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'server_error',
                        msg: 'notification.server_error'
                    });
                })
            },
        },
        destroyed() {}
    }
</script>